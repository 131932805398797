import React, { useEffect, useRef } from 'react';
import { Button, HeadingBoundary, Headline, ManagedInput } from '@panda/ui';
import { Form, Formik, FormikHelpers, useFormikContext } from 'formik';
import { chainValidators, ManagedForm, validateEmail, validateNonEmpty } from '@web-apps/forms';

import { useHistory } from 'react-router-dom';
import classnames from 'classnames';
import { useTheme } from '@web-apps/theming';
import classes from './EntryPage.scss';
import useTranslations from '../../../hooks/translations';
import { useLoadingAnimation } from '../../../hooks/LoadingAnimation';
import TacGroup from '../../../components/groups/tac/TacGroup';
import SocialButton from '../../../components/social/SocialButton/SocialButton';
import SipgateLogo from '../../../media/images/sipgate_logo.svg';
import { useOutsideAlerter } from '../../../hooks/OuterClick';
import useSignupData from '../../../hooks/SignupData';
import { SipgateDomain, SipgateProduct } from '../../../redux/slices/signup';
import { MarkdownTranslate } from '../../../utils/markdown';
import NqNupsi, { isNqDomain } from '../../../components/nqNupsi/NQNupsi';

type FormValues = {
	email: string;
};

const Infobox = ({
	headlineLK,
	listLK,
	hintLK,
}: {
	headlineLK: string;
	listLK: string;
	hintLK: string;
}) => {
	const { translate } = useTranslations();

	return (
		<>
			<HeadingBoundary>
				<Headline>{translate(headlineLK)}</Headline>
				<MarkdownTranslate translate={translate} translationKey={listLK} placeholder={[]} />
				<div className={classes.hint}>
					<MarkdownTranslate translate={translate} translationKey={hintLK} placeholder={[]} />
				</div>
			</HeadingBoundary>
		</>
	);
};

const getInfoBox = (domain: SipgateDomain, product: SipgateProduct) => {
	if (domain === 'sipgate.co.uk') {
		switch (product) {
			case 'trunking':
				return (
					<Infobox
						headlineLK="SIGNUP_UK_TRUNKING_INFOBOX_HEADLINE"
						listLK="SIGNUP_UK_TRUNKING_INFOBOX_LIST"
						hintLK="SIGNUP_UK_TRUNKING_INFOBOX_HINT"
					/>
				);
			case 'team':
			case 'team_neopbx':
			default:
				return (
					<Infobox
						headlineLK="SIGNUP_UK_INFOBOX_HEADLINE"
						listLK="SIGNUP_UK_INFOBOX_LIST"
						hintLK="SIGNUP_UK_INFOBOX_HINT"
					/>
				);
		}
	}

	switch (product) {
		case 'trunking':
			return (
				<Infobox
					headlineLK="SIGNUP_INFOBOX_TRUNKING_HEADLINE"
					listLK="SIGNUP_INFOBOX_TRUNKING_LIST"
					hintLK="SIGNUP_INFOBOX_TRUNKING_HINT"
				/>
			);
		case 'affiliate':
			return (
				<Infobox
					headlineLK="SIGNUP_INFOBOX_AFFILIATE_HEADLINE"
					listLK="SIGNUP_INFOBOX_AFFILIATE_LIST"
					hintLK="SIGNUP_INFOBOX_AFFILIATE_HINT"
				/>
			);
		case 'team':
		case 'team_neopbx':
		default:
			return (
				<Infobox
					headlineLK="SIGNUP_INFOBOX_HEADLINE"
					listLK="SIGNUP_INFOBOX_LIST"
					hintLK="SIGNUP_INFOBOX_HINT"
				/>
			);
	}
};

const EntryPage = () => {
	const { translate, fetched: translationFetched } = useTranslations();
	const { signupData, landingPageUrl, createPartialSignup } = useSignupData();
	const { domain, product, identifier, createdPartial } = signupData;
	const history = useHistory();
	const { isDark } = useTheme();

	const loadingAnimation = useLoadingAnimation();

	useEffect(() => {
		const messageListener = (event: MessageEvent) => {
			if (event.data.type === 'STORAGE_RESPONSE' && event.data.key === 'ls2hs') {
				localStorage.setItem('ls2hs', event.data.value);
			}
		};
		window.addEventListener('message', messageListener);

		window.parent.postMessage({ type: 'STORAGE_REQUEST', key: 'ls2hs' }, '*');

		return () => {
			window.removeEventListener('message', messageListener);
		};
	}, []);

	useEffect(() => {
		if (translationFetched) {
			loadingAnimation.hide();
		}
	}, [loadingAnimation, translationFetched]);

	useEffect(() => {
		if (createdPartial && identifier) {
			window.parent.location.href = `/${identifier}?hT=true`;
		}
	}, [history, createdPartial, identifier]);

	const validators = {
		email: chainValidators(validateNonEmpty(translate), validateEmail(translate)),
	};

	const validate = (values: FormValues) =>
		ManagedForm.collectData(validators, () => [], values).fieldErrors;

	const onSubmit = async (values: FormValues, formik: FormikHelpers<FormValues>) => {
		const data = ManagedForm.collectData(validators, () => [], values);
		if (data.state !== 'valid') {
			formik.setErrors(data.fieldErrors);
		}

		await createPartialSignup({ email: values.email });
	};

	const FormRedux = () => {
		const { error: signupError } = signupData;
		const formik = useFormikContext<{ email: string }>();

		useEffect(() => {
			if (signupError && signupError.name === 'EMAIL_ALREADY_EXISTS') {
				formik.setErrors({
					email: translate('SIGNUP_ERROR_EMAIL_ALREADY_EXISTS'),
				});
			}
		}, [formik, signupError]);

		return null;
	};
	const isInFrame = window.top !== window.self;

	const closeSignup = () => {
		if (isInFrame) {
			window.parent.postMessage('close-signup', '*');
			window.close();
		}
	};

	const onKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
		e.stopPropagation();
		if (e.nativeEvent.code === 'Escape') {
			closeSignup();
		}
	};

	const wrapperRef = useRef<HTMLDivElement>(null);
	useOutsideAlerter(wrapperRef, closeSignup);

	return (
		// eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
		<div className={classes.wrapper} role="presentation" onKeyDown={onKeyDown} tabIndex={0}>
			<div className={classes.container} ref={wrapperRef}>
				{isInFrame && (
					<div className={classes.close}>
						<Button icon="close" iconOnly size="xlarge" variant="quiet" onClick={closeSignup} />
					</div>
				)}
				<a
					href={landingPageUrl}
					className={classnames(
						window.top !== window.self ? classes.hide : classes.sipgateLogo,
						isDark && classes.dark
					)}
				>
					<img src={SipgateLogo} alt="sipgate logo" aria-hidden="true" />
				</a>
				<main className={classes.content}>
					<div className={classes.form}>
						<HeadingBoundary>
							<Headline>{translate('SIGNUP_ENTRY_PAGE_HEADLINE')}</Headline>
						</HeadingBoundary>
						<Formik
							initialValues={{
								email: '',
							}}
							onSubmit={onSubmit}
							validate={validate}
							validateOnBlur={false}
							validateOnChange={false}
						>
							{formik => (
								<Form className={classes.form}>
									{isNqDomain() && (
										<div className={classes.nqNupsiContainer}>
											<NqNupsi />
										</div>
									)}
									<FormRedux />
									<ManagedInput
										managedField={ManagedForm.buildField(formik, 'email')}
										label={translate('SIGNUP_LOGIN_EMAIL_LABEL')}
										placeholder={translate('PLACEHOLDER_EMAIL')}
									/>
									<Button
										type="submit"
										size="xlarge"
										variant="loud"
										width="max"
										disabled={formik.isSubmitting}
									>
										{translate('SIGNUP_ENTRY_PAGE_CONTINUE_WITH_EMAIL')}
									</Button>
								</Form>
							)}
						</Formik>
						<hr className={classes.hr} data-content={translate('SIGNUP_ENTRY_PAGE_OR')} />
						<SocialButton provider="google" label="SIGNUP_ENTRY_PAGE_GOOGLE_BUTTON" />
						<SocialButton provider="apple" label="SIGNUP_ENTRY_PAGE_APPLE_BUTTON" />
						<span className={classes.tacs}>
							<TacGroup />
						</span>
					</div>
				</main>
				<aside className={classes.info}>{getInfoBox(domain, product)}</aside>
			</div>
		</div>
	);
};

export default EntryPage;
